import React, { useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';

import './WebChat.css';
// import useAdaptiveCardsPackage from 'botframework-webchat/lib/adaptiveCards/hooks/useAdaptiveCardsPackage';
// import useAdaptiveCardsHostConfig from 'botframework-webchat/lib/adaptiveCards/hooks/useAdaptiveCardsHostConfig';

const WebChat = ({ className, onFetchToken, store, token }) => {
  const directLine = useMemo(() => createDirectLine({ token, domain: "https://europe.directline.botframework.com/v3/directline" }), [token]);

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        bubbleBackground: '#ffffff60',
        bubbleTextColor: 'black',
        bubbleBorderRadius: 15,
        bubbleFromUserBackground: '#1e81b0',
        bubbleBorderColor: '#f1f1f1',
        bubbleFromUserBorderRadius: 15,
        bubbleFromUserBorderColor: '#1e81b0',
        bubbleFromUserTextColor: 'white',
        sendBoxBackground: 'White',
        sendBoxButtonColor: "#1e81b0",
        transcriptOverlayButtonBackground: 'rgba(30, 129, 176, .6)',
        transcriptOverlayButtonColor: 'White',
        transcriptOverlayButtonBackgroundOnFocus: 'rgba(30, 129, 176, .8)',
        transcriptOverlayButtonBackgroundOnHover: 'rgba(30, 129, 176, .8)',
        fontSizeSmall: '80%',
        monospaceFont: "fontFamily(['Consolas', 'Courier New', 'monospace'])",
        primaryFont: "fontFamily(['Calibri', 'Helvetica Neue', 'Arial', 'sans-serif'])",
        accent: '#1e81b0',
        cardEmphasisBackgroundColor: "#1e81b0",
        subtle: '#1e81b0',
      }),
    []
  );

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);
  console.log("ReactWebChat is here! Final destination.");
  return token ? (
    <ReactWebChat className={`${className || ''} web-chat`} directLine={directLine} store={store} styleSet={styleSet} />
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <p>Please wait while we are connecting.</p>
      </div>
    </div>
  );
};

export default WebChat;
